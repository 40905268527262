/* eslint-disable */
import React, { useContext, useEffect, useState, SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { userService } from '@/services/userService';

//Material UI
import Toolbar from '@mui/material/Toolbar';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Card from '@mui/material/Card';

//Material-UI Icons
import ContactsIcon from '@mui/icons-material/Contacts';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

//Custom Icons
import UserIcon from '@/icons/UserIcon';

//Custom components
import FormContent from '@/components/FormContent';
import { GridContainer, GridItem } from '@/ui/Grid';
import {
  CardActionButtonSave,
  CardHeading,
  CardToolbarButtonBack,
  CardToolbarMenuButton,
  CardToolbarMenuItemActivate,
  CardToolbarMenuItemDeactivate,
  CardToolbarMenuItemDelete,
} from '@/ui/Card';
import { FormAvatar, FormHeading, FormHeadingID, FormFillContent, FormTab, FormTabPanel, FormTabs } from '@/ui/Form';
import SnackbarContext from '@/context/SnackbarContext/SnackbarContext';
import DialogContext from '@/context/DialogContext/DialogContext';
import dataController from '@/lib/DataController';
import useFormController from '@/components/useFormController';
import { FormContentMode } from '@/components/FormContent';
import { SnackbarContextType } from '@/context/SnackbarContext/SnackbarContext';
import { DialogContextType } from '@/context/DialogContext/DialogContext';
import ModelTable from '@/ui/Table/ModelTable';
import UserLicenceModel from '@/models/licence';
import { DCRecord } from '@/@types/lib/dataController';
import UserContext, { UserContextType } from '@/context/UserContext/UserContext';
import TenantAdminLicencesDialog from '@/ui/AdminDialogs/TenantAdminLicencesDialog';
import Licence from '@/models/licence';

interface IUserTenantFormProps {
  dc: dataController;
  recordId: number;
  mode: FormContentMode;
  form: string;
}

function UserTenantForm(props: IUserTenantFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const snackbarContext = useContext(SnackbarContext) as SnackbarContextType;
  const dialogContext = useContext(DialogContext) as DialogContextType;
  const userContext = useContext(UserContext) as UserContextType;

  const [currentTab, setCurrentTab] = useState(0);
  const [disableAdd, setDisableAdd] = useState(false);
  const [modules, setModules] = useState<DCRecord[]>([]);

  const { dc, recordId, mode, form } = props;
  const { validation, fields, onFieldChange, doClose, doUpdate, doDelete, doRefresh, record, pickerDataControllers, dataChanged } =
    useFormController({
      mode,
      recordId: recordId,
      form,
      dc,
      onClose: close,
      customPath: 'tenant-admin/tenants/' + String(userContext.tenantId) + '/users',
    });

  const [userLicenceRecords, setUserLicenceRecords] = useState<Array<DCRecord>>([]);

  const dcUserLicences = new dataController(
    UserLicenceModel,
    'tenant-admin/tenants/' + String(record.tenant_id) + '/users/' + String(recordId) + '/licences'
  );

  const handleClickBack = () => {
    if (doClose) {
      doClose()
        .then((resp) => {
          if (resp.success) {
            navigate('/user-tenants');
          }
        })
        .catch((result) => {
          snackbarContext.showNotification(result.error, 'error');
        });
    }
  };

  const handleTabChange = (value: number) => {
    setCurrentTab(value);
  };

  const handleUpdate = (evt: any) => {
    if (doUpdate) {
      doUpdate()
        .then((result) => {
          if (result) {
            const msg = 'messages.update_user_success';
            snackbarContext.showNotification(msg, 'success');
          }
        })
        .catch((result) => {
          if (result.success === false && result.validationPass === false) {
            //do nothing
          } else {
            const msg = 'error.update_user';
            snackbarContext.showNotification(msg, 'error');
          }
        });
    }
  };

  const handleDelete = (evt: any) => {
    if (doDelete) {
      doDelete()
        .then((result: any) => {
          if (result.success) {
            const msg = 'messages.delete_user_success';
            snackbarContext.showNotification(msg, 'success');
            navigate('/user-tenants');
            setDisableAdd(false);
          }
        })
        .catch((result) => {
          const msg = 'error.delete_user';
          snackbarContext.showNotification(msg, 'error');
        });
    }
  };

  const handleActivate = (evt: SyntheticEvent) => {
    if (record.id !== null) {
      try {
        userService.activate(Number(record.id), userContext.tenantId).then((result) => {
          //@ts-ignore
          if (result.success) {
            const msg = 'messages.activate_user_success';
            snackbarContext.showNotification(msg, 'success');
            doRefresh();
          } else {
            const msg = 'error.activate_user';
            snackbarContext.showNotification(msg, 'error');
          }
        });
      } catch (error) {
        const msg = 'error.activate_user';
        snackbarContext.showNotification(msg, 'error');
      }
    }
  };

  const handleDeactivate = (evt: SyntheticEvent) => {
    if (record.id !== null) {
      try {
        userService.deactivate(Number(record.id), userContext.tenantId).then((result) => {
          //@ts-ignore
          if (result.success) {
            const msg = 'messages.deactivate_user_success';
            snackbarContext.showNotification(msg, 'success');
            //close({ dataChanged: true, action: 'update' });
            navigate(-1);
          } else {
            const msg = 'error.deactiavate_user';
            snackbarContext.showNotification(msg, 'error');
          }
        });
      } catch (error) {
        const msg = 'error.deactivate_user';
        snackbarContext.showNotification(msg, 'error');
      }
    }
  };

  const handleRowAction = (action: string, id: number, initial_record?: { [key: string]: any }) => {
    if (action === 'edit') {
      dialogContext.showDialog(TenantAdminLicencesDialog, {
        mode: 'update',
        form: 'update_user_licence',
        type: 'tenant-admin',
        dataId: id,
        onClose: () => {},
        refreshRecords: getLicences,
        initialRecord: initial_record ? initial_record : undefined,
        tenantId: record.tenant_id,
        userId: recordId,
      });
    }
  };

  const commonProps = {
    record: record,
    validation: validation,
    onFieldChange: onFieldChange,
    fields: fields,
    mode: mode,
    pickerDataControllers: pickerDataControllers,
  };

  const title = record.first_name ? (record.first_name as string) : undefined;

  const showSaveButton = currentTab === 0 || currentTab === 1;
  const disableSaveButton = !dataChanged;

  useEffect(() => {
    if (record && record !== null && record.tenant_id !== undefined && record.tenant_id !== null && userLicenceRecords.length === 0) {
      getLicences();
      if (modules.length === 0) {
        dcUserLicences.GetData('tenant-admin/tenants/' + record.tenant_id + '/licences?view=picker').then((resp) => {
          if (resp.data && Array.isArray(resp.data)) {
            setModules(resp.data);
            checkAllUsed();
          }
        });
      }
    }
  }, [record]);

  useEffect(() => {
    checkAllUsed();
  }, [userLicenceRecords]);

  const checkAllUsed = () => {
    if (userLicenceRecords.length == 0) {
      setDisableAdd(false);
      return;
    }
    let foundAll = true;
    modules.forEach((module) => {
      let foundOne = false;
      if (userLicenceRecords.find((licence) => licence.tenant_licence_id === module.id)) {
        foundOne = true;
      }
      if (foundOne === false) foundAll = false;
    });

    setDisableAdd(foundAll);
  };

  const getLicences = useCallback(() => {
    if (!userContext || !userContext.tenantId) {
      console.log('User context is not ready');
      return;
    }

    const dcUserLicences = new dataController(
      UserLicenceModel,
      'tenant-admin/tenants/' + String(record.tenant_id) + '/users/' + String(recordId) + '/licences'
    );

    dcUserLicences
      .GetData('tenant-admin/tenants/' + userContext.tenantId + '/users/' + recordId + '/licences')
      .then((resp) => {
        if (resp.data && Array.isArray(resp.data) && resp.data.length > 0) {
          setUserLicenceRecords(resp.data);
        } else {
          setUserLicenceRecords([]);
        }
      })
      .catch((error) => {
        console.error('Error fetching licences: ', error);
      });
  }, [userContext, record.tenant_id, recordId]);

  return (
    <Card>
      <CardHeading>
        <Toolbar variant="dense" disableGutters={true}>
          <FormAvatar ariaLabel="user" icon={<UserIcon />} />
          <FormHeading header={title} subheaderContent={<FormHeadingID id={recordId} />} dataChanged={dataChanged} />
          <FormFillContent />
          <CardToolbarButtonBack onClick={handleClickBack} />
          <CardToolbarMenuButton>
            {record.status === 20 ? (
              <CardToolbarMenuItemDeactivate onClick={handleDeactivate} />
            ) : (
              <CardToolbarMenuItemActivate onClick={handleActivate} />
            )}
            {record.user_type_id !== 2 ? <CardToolbarMenuItemDelete onClick={handleDelete} /> : null}
          </CardToolbarMenuButton>
        </Toolbar>
      </CardHeading>
      <CardContent style={{ paddingTop: 0 }}>
        <FormTabs value={currentTab} onChange={handleTabChange} identifier={dc.getSource()}>
          <FormTab id="basic" label={t('core:common.tabs.basic')} icon={<BusinessCenterIcon />} aria-label="basic" value={0} />
          <FormTab id="licences" label={t('core:user.tabs.licences')} icon={<ContactsIcon />} aria-label="licences" value={1} />
        </FormTabs>
        <FormTabPanel value={currentTab} index={0}>
          <GridContainer direction="row" spacing={3}>
            <GridItem>
              <GridContainer direction="row">
                <FormContent
                  title={t('admin:common.sections.basic') as string}
                  fieldNames={['first_name', 'last_name', 'username', 'email', 'app_permissions_tenants', 'status', 'remarks', 'tenant_id']}
                  {...commonProps}
                  columns={2}
                />
              </GridContainer>
            </GridItem>
          </GridContainer>
        </FormTabPanel>
        <FormTabPanel value={currentTab} index={1}>
          {record && record !== null && record.tenant_id !== undefined && record.tenant_id !== null ? (
            <ModelTable
              title={t('admin:tenant.sections.licences') as string}
              key={`tblid-tenant-admin-user-licences`}
              dc={dcUserLicences}
              records={userLicenceRecords}
              allowSelection="one"
              allowRowAction={() => true}
              onRowAction={handleRowAction}
              viewName="tenant_admin_user"
              allowColumnPicker
              allowFilter={true}
              allowAdd={true}
              addLabel={t('buttons.new_f') as string}
              handleAdd={() => {
                dialogContext.showDialog(TenantAdminLicencesDialog, {
                  mode: 'insert',
                  form: 'insert_user_licence',
                  dataId: undefined,
                  type: 'tenant-admin',
                  onClose: () => {},
                  refreshRecords: getLicences,
                  initialRecord: undefined,
                  tenantId: record.tenant_id,
                  userId: recordId,
                });
              }}
              disableAdd={disableAdd}
            />
          ) : null}
        </FormTabPanel>
      </CardContent>
      <CardActions>
        <FormFillContent />
        {showSaveButton ? <CardActionButtonSave onClick={handleUpdate} variant="contained" disabled={disableSaveButton} /> : null}
      </CardActions>
    </Card>
  );
}

export default UserTenantForm;
