/* eslint-disable */
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Toolbar from '@mui/material/Toolbar';
import EditIcon from '@mui/icons-material/Edit';
import AddCommentIcon from '@mui/icons-material/AddComment';

// Custom Components
import Cancel from '@mui/icons-material/Cancel';
import Check from '@mui/icons-material/Check';
import DeleteForever from '@mui/icons-material/DeleteForever';
import { Button } from '@mui/material';
import DraggableDialog from '@/ui/Dialog/DraggableDialog';
import DialogHeader from '@/ui/Dialog/DialogHeader';
import DialogToolbarHeading from '@/ui/Dialog/DialogToolbarHeading';
import DialogToolbarButtonClose from '@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose';
import ToolbarFillContent from '@/ui/Toolbar/ToolbarFillContent';
import DialogBody from '@/ui/Dialog/DialogBody';
import useFormController from '@/components/useFormController';
import GridContainer from '@/ui/Grid/GridContainer';
import FormContent from '@/components/FormContent';

import DialogContext, {
  IDialogProps,
} from '@/context/DialogContext/DialogContext';
import SnackbarContext from '@/context/SnackbarContext/SnackbarContext';
import DataController from '@/lib/DataController';
import modelComments from '@/models/comments';
import DialogToolbarMenuButton from '@/ui/Dialog/DialogToolbarMenuButton';
import DialogToolbarMenuItemDelete from '@/ui/Dialog/ToolbarMenuItems/DialogToolbarMenuItemDelete';
import DialogActionButtonCancel from '@/ui/Dialog/ActionButtons/DialogActionButtonCancel';
import DialogActionButtonSave from '@/ui/Dialog/ActionButtons/DialogActionButtonSave';

const CommentDialog = (props: IDialogProps) => {
  const { mode, recordId, onClose, form, initialRecord, level, baseRecordPath } = props;

  const { t } = useTranslation();
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);

  const dc = new DataController(modelComments, baseRecordPath);

  const close = (result: any) => {
    if (onClose) {
      onClose(result);
    }
    
    if (level) {
      dialogContext.hideDialog(level);
    } else {
      dialogContext.hideDialog();
    }
   
  };

  const {
    record,
    validation,
    fields,
    onFieldChange,
    doInsert,
    doUpdate,
    doDelete,
  } = useFormController({
    mode,
    recordId: recordId,
    form,
    dc,
    onClose: close,
    initialRecord,
  });

  const handleFieldChange = (value: any, source: string) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleClose = () => {
    close({ success: false });
  };

  const handleInsert = () => {
    if (doInsert) {
      doInsert()
        .then((result) => {
          if (result.success) {
            const msg = t('messages.comment_insert');
            snackbarContext.showNotification(msg, 'success');
            close({ dataChanged: true, action: 'insert' });
          } else if (result.error) {
            const msg = t('error.addRecord') + result.error;
            snackbarContext.showNotification(msg, 'error');
          } else if (result.validationPass === false) {
            // do nothing
          }
        })
        .catch((resp) => {
          snackbarContext.showNotification(resp.error, 'error');
        });
    }
  };

  const handleUpdate = () => {
    if (doUpdate) {
      doUpdate()
        .then((result) => {
          if (result.success) {
            const msg = t('messages.comment_update');
            snackbarContext.showNotification(msg, 'success');
            close({ dataChanged: true, action: 'update' });
          } else if (result.error) {
            const msg = t('error.editRecord') + result.error;
            snackbarContext.showNotification(msg, 'error');
          } else if (result.validationPass === false) {
            // do nothing
          }
        })
        .catch((resp) => {
          snackbarContext.showNotification(resp.error, 'error');
        });
    }
  };

  const handleDelete = () => {
    doDelete().then((result) => {
      if (result.success) {
        snackbarContext.showNotification('delete', 'success');
        close({ dataChanged: true, action: 'delete' });
      }
    }).catch((resp) => {
      if (resp.error) {
        const msg = t('error.delete') + resp.error;
        snackbarContext.showNotification(msg, 'error');
      }
    });
  };

  return (
    <DraggableDialog open maxWidth="sm" onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters>
          {mode === 'update' ? (
            <EditIcon sx={{ transform: 'scale(1.3)' }} />
          ) : mode === 'insert' ? (
            <AddCommentIcon sx={{ transform: 'scale(1.3)' }} />
          ) : null}
          <DialogToolbarHeading>
            {mode === 'insert'
              ? t('titles.add_comment')
              : mode === 'update'
                ? t('titles.edit_comment')
                : null}
          </DialogToolbarHeading>
          <ToolbarFillContent />
          {mode === 'update' && <DialogToolbarMenuButton>
            <DialogToolbarMenuItemDelete onClick={handleDelete} />
          </DialogToolbarMenuButton>}
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          <GridContainer direction="row" spacing={3}>
            <FormContent
              fieldNames={dc.getFormFieldsNames(form)}
              record={record}
              validation={validation}
              fields={fields}
              onFieldChange={handleFieldChange}
              mode={mode}
              columns={1}
            />
          </GridContainer>
        </Box>
      </DialogBody>
      <DialogActions >
        <DialogActionButtonCancel variant="outlined" onClick={handleClose} />
        {mode === 'insert'
          ? <DialogActionButtonSave variant="contained" onClick={handleInsert} />
          : mode === 'update'
            ? <DialogActionButtonSave variant="contained" onClick={handleUpdate} />
            : null}
      </DialogActions>
    </DraggableDialog>
  );
};

export default CommentDialog;
