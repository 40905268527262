import React, { useContext, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

// OL
import { Geometry } from 'ol/geom';
import { Feature } from 'ol';

// MUI
import Toolbar from '@mui/material/Toolbar';
import { WbTwilight } from '@mui/icons-material';
import { DialogContent } from '@mui/material';

// Custom Components
import useFormController from '@/components/useFormController';
import DynamicDialog from '@/ui/Dialog/DynamicDialog';
import DialogHeader from '@/ui/Dialog/DialogHeader';
import DialogToolbarButtonClose from '@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose';
import ToolbarFillContent from '@/ui/Toolbar/ToolbarFillContent';
import DialogContext, { IDialogProps } from '@/context/DialogContext/DialogContext';
import FormContent from '@/components/FormContent';
import FormAvatar from '@/ui/Form/FormAvatar';
import FormHeading from '@/ui/Form/FormHeading';

// Types
import { ClosingDetails, FormControllerProps } from '@/@types/components/formController';
import { GridContainer, GridItem } from '@/ui/Grid';
import FeatureMap from '@/views/GIS/FeatureMap';

const PlanRasvjeteDialog: FunctionComponent<IDialogProps> = (props) => {
  const { hideDialog } = useContext(DialogContext);
  const { t } = useTranslation();

  const { mode, onClose, mjestoGeom } = props;
  const { record, validation, dataChanged, fields, onFieldChange, doClose } = useFormController(props as FormControllerProps);

  const handleFieldChange = (value: any, source: string) => {
    if (onFieldChange) {
      onFieldChange(value, source);
    }
  };

  const handleClose = () => {
    if (doClose) {
      doClose().then((result) => {
        if (result.success) {
          close({ dataChanged: false, action: 'cancel' });
        }
      });
    }
  };

  const close = (result: ClosingDetails) => {
    if (result.dataChanged || dataChanged) {
      if (onClose) {
        onClose({ dataChanged: true, action: result.action });
      }
    }

    hideDialog();
  };

  const title = t('rasvjeta:titles.plan_rasvjete') as string; // TODO: more info ?

  return (
    <DynamicDialog open maxWidth="xl" onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters>
          <FormAvatar icon={<WbTwilight />} ariaLabel="hiker" />
          <FormHeading header={title} /* subheaderContent={<FormDataSubHeading record={record} items={[]} />} */ />
          <ToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogContent sx={{ py: 0, px: 1, width: '100%' }}>
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <GridContainer sx={{ mt: '4px' }}>
              <FormContent
                fieldNames={['ime_jls', 'godina', 'zona_rasvijetljenosti_id']}
                record={record}
                validation={validation}
                fields={fields}
                onFieldChange={handleFieldChange}
                mode={mode}
                columns={3}
              />
              <FormContent
                fieldNames={['zona_rasvijetljenosti_opis', 'opis_podrucja']}
                record={record}
                validation={validation}
                fields={fields}
                onFieldChange={handleFieldChange}
                mode={mode}
                columns={1}
              />
              <FormContent
                fieldNames={['svjetlostaj_od', 'svjetlostaj_do', 'zastita', 'povrsina']}
                record={record}
                validation={validation}
                fields={fields}
                onFieldChange={handleFieldChange}
                mode={mode}
                columns={2}
              />
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={6}>
            {record ? (
              <FeatureMap mapId={33} secondaryFeature={mjestoGeom} feature={record?.geom as Feature<Geometry>} mode="view" />
            ) : null}
          </GridItem>
        </GridContainer>
      </DialogContent>
    </DynamicDialog>
  );
};

export default PlanRasvjeteDialog;
