import React, { useState, useEffect, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";

// OpenLayers
import Geometry from "ol/geom/Geometry";
import proj4 from "proj4";
import { register as OlRegister } from "ol/proj/proj4";
import OlSourceVector from "ol/source/Vector";
import { DrawEvent } from "ol/interaction/Draw";
import OlBaseLayer from "ol/layer/Base";
import OlFeature from "ol/Feature";
import OlCollection from "ol/Collection";
import * as OlExtent from "ol/extent";

// Custom
import { Controls, ZoomControl, ScaleLineControl } from "@/components/Map/Controls";
import Map from "@/components/Map/Map";
import { Layers, VectorLayer } from "@/components/Map/Layers";
import { Overlays } from "@/components/Map/Overlays";
import { Interactions, DefaultInteractions, DrawInteraction } from "@/components/Map/Interactions";
import { measuringStyle, selectedStrokeOnlyStyle, highlightedVectorCustom } from "@/components/Map/mapStyles";
import UserContext, { UserContextType } from "@/context/UserContext/UserContext";

import GeoBaseLayerSwitcher from "@/components/Map/Controls/GEO/GeoBaseLayerSwitcher";
import GeoAPILayers from "@/components/Map/Layers/GEO/GeoAPILayers";

// d.ts
import { ViewOptionsType } from "@/@types/common";

// Services
import mapService from "@/services/mapService";


type FeatureMapMode = "insert" | "view" | "update"

type FeatureMapProps = {
  mapId?: number;
  feature?: OlFeature;
  secondaryFeature?: OlFeature;
  mode: FeatureMapMode;
  geometryChangeType?: "LineString" | "Polygon" | "Point"
  onGeometryChange?: (geom: OlFeature) => void;
};

const FeatureMap = (props: FeatureMapProps) => {
  const userContext = useContext(UserContext) as UserContextType;
  const { t } = useTranslation();

  const { feature, secondaryFeature, mapId, geometryChangeType } = props;

  const { mode, onGeometryChange } = props;

  const [zoomToExtent, setZoomToExtent] = useState<number[] | undefined>(undefined);

  const [mapInitialized, setMapInitialized] = useState(false);

  const [viewOptions, setViewOptions] = useState<ViewOptionsType>({
    center: userContext?.mapSettings ? userContext.mapSettings.initial_view_center : [1731757, 5581737],
    zoom: userContext?.mapSettings ? userContext.mapSettings.initial_view_zoom : 14,
    extent: userContext?.mapSettings ? userContext.mapSettings.max_extent : undefined,
    // projection: wgs84PM,
    minZoom: 4,
    maxZoom: 17,
    constrainResolution: true
  });

  const [layersCollection, setLayersCollection] = useState<OlCollection<OlBaseLayer> | undefined>(undefined);

  const [selectedSource, setSelectedSource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));
  const [secondarySelectedSource, setSecondarySelectedSource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));

  const [drawingSource,] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));

  proj4.defs(
    "EPSG:3765",
    "+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
  );
  proj4.defs(
    "EPSG:3857",
    "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"
  );
  OlRegister(proj4);

  useEffect(() => {
    getLayers();
  }, []);

  useEffect(() => {
    if (feature) {
      setSelectedSource(new OlSourceVector<Geometry>({ features: [feature] }));

      const geom = feature.getGeometry();
      if (geom) {
        const extent = geom.getExtent();
        const center = OlExtent.getCenter(extent) as [number, number];
        setViewOptions(prevState => ({
          ...prevState,
          center
        }));
        setZoomToExtent(extent);
      }
    } else {
      setSelectedSource(new OlSourceVector<Geometry>({ features: [] }));
    }
  }, [feature]);

  useEffect(() => {
    if (secondaryFeature) {
      setSecondarySelectedSource(new OlSourceVector<Geometry>({ features: [secondaryFeature] }));

    } else {
      setSecondarySelectedSource(new OlSourceVector<Geometry>({ features: [] }));
    }
  }, [secondaryFeature]);


  const getLayers = () => {
    mapService.getLayers(mapId || 3).then((coll) => {
      setLayersCollection(coll);
      setMapInitialized(true);
    });
  };

  const handleDrawMeasureStart = useCallback(() => {
  }, []);

  const handleDrawMeasureEnd = useCallback((evt: DrawEvent) => {
    if (evt.feature) {
      setSelectedSource(new OlSourceVector({ features: [evt.feature] }));
      if (onGeometryChange) {
        onGeometryChange(evt.feature);
      }
    }
  }, [onGeometryChange]);

  const handleDrawMeasureChange = useCallback(() => {
    // console.log("handleDrawChange", evt);
  }, []);

  // getDefaultData() {
  //   mapService.getDefaultData().then((data) => {
  //     if (data) {
  //       const viewData = Array.isArray(data) ? Object.assign({}, data[0]) : Object.assign({}, data);
  //       this.setState((prevState) => {
  //         return {
  //           ...prevState,
  //           viewOptions: {
  //             ...prevState.viewOptions,
  //             zoom: viewData.initial_view_zoom,
  //             center: viewData.initial_view_center
  //           },
  //           zoomToExtent: viewData.default_extent
  //         };
  //       });
  //     }
  //   });
  // }

  return (
      userContext && layersCollection ? (
        <Map
          height="400px"
          view={viewOptions}
          className="feature-map"
          id="feature-map"
          initialized={mapInitialized}
          moveTolerance={10}
          maxTilesLoading={10}
          zoomToExtent={zoomToExtent}
          zoomToExtentPadding={[20, 20, 20, 20]}
        >
          <Controls>
            <ZoomControl zoomInTipLabel={t("map:controls.zoom_in") as string} zoomOutTipLabel={t("map:controls.zoom_out") as string} />
            <ScaleLineControl type="minimap" />
            <GeoBaseLayerSwitcher mapId={mapId || 3} allowNoLayer={false} />
          </Controls>
          <Layers>
            {layersCollection ? (
              <Layers>
                <GeoAPILayers layersCollection={layersCollection} />
                <VectorLayer id="selected" source={selectedSource} style={selectedStrokeOnlyStyle} zIndex={1000} />
                <VectorLayer id="secondarySelected" source={secondarySelectedSource} style={highlightedVectorCustom} zIndex={1001} />
              </Layers>
            ) : null}
          </Layers>
          <Overlays/>
          <Interactions>
            {mode === "view" || mode === "insert" || mode === "update" ?
              <DefaultInteractions /> : null}
            {/* {mode === "view" || mode === "update" ?
              <MouseWheelZoomInteraction useAnchor={false} /> : null} */}
            {mode === "insert" || mode === "update" ?
              <DrawInteraction
                source={drawingSource}
                type={!geometryChangeType ? "Point" : geometryChangeType}
                style={measuringStyle}
                onChange={(handleDrawMeasureChange)}
                onDrawstart={handleDrawMeasureStart}
                onDrawend={handleDrawMeasureEnd}
              
              />
              : null}

          </Interactions>
        </Map>
      ) : null
  );
}

export default FeatureMap;
