/* eslint-disable */
import React, { useContext } from 'react';

// Custom Components
import Permissions from '@/lib/permissions';
import AppPage from '@/ui/AppPage/AppPage';

// Models
import modelRasvjetaMjesta from '@/models/rasvjeta_mjesta';
import modelRasvjetaTijela from '@/models/rasvjeta_tijela';

// Types
import DataCentricPage from '@/ui/DataCentric/DataCentricPage';
import { DCRecord } from '@/@types/lib/dataController';
import DialogContext, { DialogContextType } from '@/context/DialogContext/DialogContext';
import PlanRasvjeteDialog from '../Dialogs/PlanRasvjeteDialog';
import DataController from '@/lib/DataController';
import PlanRasvjete from '@/models/plan_rasvjete';
import { Point } from 'ol/geom';
import { Feature } from 'ol';

type RasvjetaMjestaDataCentricPageParams = {};

const RasvjetaMjestaDataCentricPage = (props: RasvjetaMjestaDataCentricPageParams) => {
  const { showDialog } = useContext(DialogContext) as DialogContextType;

  const dcPlan = new DataController(PlanRasvjete);

  const showPlanRasvjeteDialog = (id: number, mjestoGeom: Feature<Point>) => {
    showDialog(PlanRasvjeteDialog, {
      dc: dcPlan,
      mode: 'view',
      recordId: id,
      onClose: () => {},
      mjestoGeom,
    });
  };

  return (
    <AppPage titlett="rasvjeta:page.rasvjeta_mjesta" needPermission={[Permissions.Rasvjeta]}>
      <DataCentricPage
        primaryModel={modelRasvjetaMjesta}
        primaryTitleToken="titles.rasvjeta_mjesta"
        primaryView="datacentric"
        secondaryModel={modelRasvjetaTijela}
        secondaryTitleToken="titles.rasvjeta_tijela"
        secondaryView="default"
        baseRecordPath={modelRasvjetaMjesta.apiPath}
        childRecordRelativePath="tijela"
        allowShowImages
        geomRelativePath=""
        mapId={31}
        mapModel={modelRasvjetaMjesta}
        onCustomTableAction={(action: string, id: number, record?: { [key: string]: any }) => {
          showPlanRasvjeteDialog(record?.plan_rasvjete_id, record?.geom);
        }}
        allowCustomTableAction={(action: string, record: DCRecord, id: number) => {
          if (action === 'show_plan_rasvjete') {
            if (record?.plan_rasvjete_id) {
              return true;
            }
          }
          return false;
        }}
      />
    </AppPage>
  );
};

export default RasvjetaMjestaDataCentricPage;
