/* eslint-disable */
import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';
import commonFields from './helpers/commonFields';

const RasvjetaMjesta: IModel = {
  title: 'Rasvjeta mjesta',
  apiPath: 'rasvjeta/mjesta',
  layer: 'rasvjetna_mjesta',
  forms: {
    default: {
      fields: [
        'id',
        'oznaka',
        'lokacija',
        'tip',
        'napomena',
        'snaga_ukupna',
        'broj_svjetala',
        'omm_id',
      ],
    },
  },
  listViews: {
    default: {
      fields: [
        'id',
        'oznaka',
        'lokacija',
        'tip',
        'napomena',
        'snaga_ukupna',
        'broj_svjetala',
        'omm_id',
        'docs'
      ],
    },
    datacentric: {
      fields: [
        'id',
        'oznaka',
        'lokacija',
        'tip',
        'napomena',
        'snaga_ukupna',
        'broj_svjetala',
        'omm_id',
        'docs',
        'show_plan_rasvjete'
      ],
    },
  },
  fields: [
    coreFields.id,
    {
      title: 'oznaka',
      source: 'oznaka',
      ttoken: 'rasvjeta:mjesta.oznaka',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'lokacija',
      source: 'lokacija',
      ttoken: 'rasvjeta:mjesta.lokacija',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'tip',
      source: 'tip',
      ttoken: 'rasvjeta:mjesta.tip',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'napomena',
      source: 'napomena',
      ttoken: 'rasvjeta:mjesta.napomena',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'snaga_ukupna',
      source: 'snaga_ukupna',
      ttoken: 'rasvjeta:mjesta.snaga_ukupna',
      type: 'numeric',
      readonly: true,
      filter: true,
    },
    {
      title: 'broj_svjetala',
      source: 'broj_svjetala',
      ttoken: 'rasvjeta:mjesta.broj_svjetala',
      type: 'numeric',
      readonly: true,
      filter: true,
    },
    {
      title: 'omm_id',
      source: 'omm_id',
      ttoken: 'rasvjeta:mjesta.omm_id',
      type: 'picker',
      subModel: {
        apiPath: 'rasvjeta/omm',
        format: '{label}',
        idattr: 'id',
      },
      readonly: true,
      filter: true,
    },
    {
      title: 'geometry',
      source: 'geom',
      ttoken: 'comments.geometry',
      type: 'wkt',
      sourceProjection: 'EPSG:3765',
      targetProjection: 'EPSG:3857',
      readonly: true,
    },
    {
      title: 'plan_rasvjete_id',
      source: 'plan_rasvjete_id',
      ttoken: "rasvjeta:mjesta.plan_rasvjete",
      type: "numeric"
    },
    {
      title: '',
      source: 'show_plan_rasvjete',
      tooltip: 'rasvjeta:buttons.show_plan_rasvjete',
      ttoken: '',
      type: 'button',
      icon: 'wb_twilight',
      action: 'show_plan_rasvjete',
      padding: 'none',
      sort: false,
    },
    coreFields.gid,
    coreFields.docs,
    coreFields.created_by,
    coreFields.created_on,
    coreFields.modified_by,
    coreFields.modified_on,
  ],
};

export default RasvjetaMjesta;
