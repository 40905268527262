// import HomePage from '@/views/HomePage/HomePage';
// import GISHomePage from '@/views/GIS/GISHomePage';
// import MapPage from '@/views/GIS/MapPage/MapPage';
// import ProfilePage from '@/views/ProfilePage/ProfilePage';
// import AdminHomePage from '@/views/Administration/AdminHomePage';
// import SettingsPage from '@/views/Administration/Settings/SettingsPage';
// import UsersPage from '@/views/Administration/UsersPage/UsersPage';
// import LogPage from '@/views/Administration/Log/LogPage';
// import TestPage from '@/views/TestPage/TestPage';
// import TestView from '@/views/TestPage/TestView';
// import GraphPage from '@/views/GraphPage/GraphPage';

import { ComponentClass, FunctionComponent } from 'react';

import HomePage from '@/views/core/HomePage';
import LoginPage from '@/views/core/Auth/LoginPage';
import MapPage from '@/views/core/MapPage';
import PasswordResetRequestPage from '@/views/core/Auth/PasswordResetRequestPage';
import PasswordResetPage from '@/views/core/Auth/PasswordResetPage';
import CommentsDataCentricPage from '@/views/Comments/CommentsDataCentricPage';
import ThreeDPage from '@/views/ThreeD/ThreeDPage';
import TenantsPage from '@/views/Tenants/TenantsPage';
import TenantPage from '@/views/Tenants/TenantPage';
import UsersPage from '@/views/Users/UsersPage';
import UserPage from '@/views/Users/UserPage';
import ModelsPage from '@/views/ModelsAdministration/ModelsPage';
import ModelPage from '@/views/ModelsAdministration/ModelPage';
import MapLayersPage from '@/views/MapLayersAdministration/MapLayersPage';
import MapLayerPage from '@/views/MapLayersAdministration/MapLayerPage';
import TestPage from '@/views/TestPage/TestPage';
import KomunalniObvezniciDataCentricPage from '@/views/Ena/KomunalniObvezniciPage/KomunalniObvezniciDataCentricPage';
import ObjektiPhotoCentricPage from '@/views/Ena/Objekti/ObjektiPhotoCentricPage';
import TranslatinoAdministrationPage from '@/views/TranslationAdministration/TranslatinoAdministrationPage';
import ProfilePage from '@/views/core/ProfilePage/ProfilePage';
import ThreeDAdminPage from '@/views/ThreeDAdministration/ThreeDAdminPage';
import ThreeDSettingAdminPage from '@/views/ThreeDAdministration/ThreeDSettingAdminPage';
import MapLegendsPage from '@/views/MapLegendsAdministration/MapLegendsPage';
import MapLegendPage from '@/views/MapLegendsAdministration/MapLegendPage';
import EnaSettingsPage from '@/views/EnaSettingsAdministration/EnaSettingsPage';
import EnaSettingPage from '@/views/EnaSettingsAdministration/EnaSettingPage';
import AppLogsPage from '@/views/AppLogsAdministration/AppLogsPage';
import GrobnaMjestaDataCentricPage from '@/views/Groblja/GrobnaMjestaPage/GrobnaMjestaDataCentricPage';
import GrobnaMjestaPhotoCentricPage from '@/views/Groblja/GrobljaPhotodoc/GrobnaMjestaPhotoCentricPage';
import LoginLogsPage from '@/views/LoginLogsPage/LoginLogsPage';
import NerazvrstaneCesteMapCentricPage from '@/views/NerazvrstaneCeste/NerazvrstaneCestePage/NerazvrstaneCesteMapCentricPage';
import RasvjetaMjestaDataCentricPage from '@/views/Rasvjeta/RasvjetaMjestaPage/RasvjetaMjestaDataCentricPage';
import RasvjetaMjestaPhotoCentricPage from '@/views/Rasvjeta/RasvjetaPhotodoc/RasvjetaMjestaPhotoCentricPage';
import ParkingMjestaPhotoCentricPage from '@/views/Parking/ParkingMjestaPhotodoc/ParkingMjestaPhotoCentricPage';
import UserTenantsPage from '@/views/UserTenants/UserTenantsPage';
import UserTenantPage from '@/views/UserTenants/UserTenantPage';
import KuceZaOdmorDataCentricPage from '@/views/Ena/KuceZaOdmor/KuceZaOdmorDataCentricPage';
import SearchSettingsPage from '@/views/SearchAdministration/SearchSettingsPage';
import AdministrationCommentsDataCentricPage from '@/views/CommentsAdministration/CommentsAdministrationDataCentricPage';

// Types
// import { RouteType } from '@/@types/common';

export type RouteType = {
  path: string;
  name: string;
  component: ComponentClass<any, any> | FunctionComponent<any>;
};

const routes: RouteType[] = [
  { path: '/', name: 'Home', component: HomePage },
  { path: '/login', name: 'Login', component: LoginPage },
  { path: '/map', name: 'Map', component: MapPage },
  {
    path: '/password-reset-request',
    name: 'Password Reset Request',
    component: PasswordResetRequestPage,
  },
  {
    path: '/password-reset',
    name: 'Reset Password',
    component: PasswordResetPage,
  },
  {
    path: '/profile',
    name: 'Profile Page',
    component: ProfilePage,
  },
  {
    path: '/comments',
    name: 'Comments Management',
    component: CommentsDataCentricPage,
  },
  { path: '/3d', name: '3D', component: ThreeDPage },
  { path: '/admin/tenants', name: 'tenants', component: TenantsPage },
  {
    path: '/admin/tenants/:id',
    name: 'tenant',
    component: TenantPage,
  },
  {
    path: '/admin/users',
    name: 'users',
    component: UsersPage,
  },
  {
    path: '/user-tenants',
    name: 'user-tenants',
    component: UserTenantsPage,
  },
  {
    path: '/user-tenants/:id',
    name: 'user-tenant',
    component: UserTenantPage,
  },
  {
    path: '/admin/users/:id',
    name: 'user',
    component: UserPage,
  },
  {
    path: '/admin/translations',
    name: 'translations',
    component: TranslatinoAdministrationPage,
  },
  {
    path: '/admin/models',
    name: 'models',
    component: ModelsPage,
  },
  {
    path: '/admin/models/:id',
    name: 'model',
    component: ModelPage,
  },
  {
    path: '/admin/map_layers',
    name: 'map_layers',
    component: MapLayersPage,
  },
  {
    path: '/admin/map_layers/:id',
    name: 'map_layer',
    component: MapLayerPage,
  },
  {
    path: '/admin/3d_settings',
    name: '3d_settings',
    component: ThreeDAdminPage,
  },
  {
    path: '/admin/3d_settings/:id',
    name: '3d_setting',
    component: ThreeDSettingAdminPage,
  },
  {
    path: '/admin/map_legends',
    name: 'map_legends',
    component: MapLegendsPage,
  },
  {
    path: '/admin/map_legends/:id',
    name: 'map_legend',
    component: MapLegendPage,
  },
  {
    path: '/admin/ena_settings',
    name: 'ena_settings',
    component: EnaSettingsPage,
  },
  {
    path: '/admin/ena_settings/:id',
    name: 'ena_setting',
    component: EnaSettingPage,
  },
  {
    path: '/admin/search_settings',
    name: 'search_settings',
    component: SearchSettingsPage,
  },
  {
    path: '/admin/app_logs',
    name: 'app_logs',
    component: AppLogsPage,
  },
  {
    path: '/admin/login_logs',
    name: 'login_logs',
    component: LoginLogsPage,
  },
  // { path: '/gis/map', name: 'Map', component: MapPage },
  // { path: '/gis', name: 'GIS Home Page', component: GISHomePage },
  // { path: '/admin/log', name: 'Log', component: LogPage },
  // { path: '/admin/users', name: 'Map', component: UsersPage },
  // {
  //   path: '/admin/settings',
  //   name: 'Admin Settings Page',
  //   component: SettingsPage,
  // },
  // { path: '/admin', name: 'Admin Home Page', component: AdminHomePage },
  // { path: '/test/:id', name: 'Test Page Detail', component: TestView },
  // { path: '/test', name: 'Test Page', component: TestPage },
  // { path: '/graphs', name: 'Graph Page', component: GraphPage },

  {
    path: '/ena/utility',
    name: 'Utility Obligations',
    component: KomunalniObvezniciDataCentricPage,
  },
  {
    path: '/ena/photodoc',
    name: 'Photo Documentation',
    component: ObjektiPhotoCentricPage,
  },
  {
    path: '/ena/kuce-za-odmor',
    name: 'Kuce Za Odmor',
    component: KuceZaOdmorDataCentricPage,
  },

  {
    path: '/nerazvrstane-ceste',
    name: 'Nerazvrstane Ceste',
    component: NerazvrstaneCesteMapCentricPage,
  },

  {
    path: '/groblja/grobna-mjesta',
    name: 'Grobna Mjesta',
    component: GrobnaMjestaDataCentricPage,
  },
  {
    path: '/groblja/photodoc',
    name: 'Groblja Photo Documentation',
    component: GrobnaMjestaPhotoCentricPage,
  },

  {
    path: '/rasvjeta/mjesta',
    name: 'Rasvjetna Mjesta',
    component: RasvjetaMjestaDataCentricPage,
  },
  {
    path: '/rasvjeta/photodoc',
    name: 'Rasvjeta Photo Documentation',
    component: RasvjetaMjestaPhotoCentricPage,
  },

  {
    path: '/parking',
    name: 'Parking Photo Documentation',
    component: ParkingMjestaPhotoCentricPage,
  },
  {
    path: '/admin/comments',
    name: 'Admin Comments',
    component: AdministrationCommentsDataCentricPage,
  },
  { path: '/test', name: 'Test Page', component: TestPage },
];

export default routes;
