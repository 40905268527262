/* eslint-disable */
import { IModel } from '@/@types/models/model';
import coreFields from './helpers/coreFields';
import commonFields from './helpers/commonFields';

const PlanRasvjete: IModel = {
  title: 'Plan rasvjete',
  apiPath: 'rasvjeta/plan',
  layer: 'plan_rasvjete',
  forms: {
    default: {
      fields: [
        'ime_jls',
        'godina',
        'zona_rasvijetljenosti_id',
        'zona_rasvijetljenosti_opis',
        'opis_podrucja',
        'svjetlostaj_od',
        'svjetlostaj_do',
        'zastita',
        'povrsina',
      ],
    },
  },
  listViews: {
    default: {
      fields: [
        'ime_jls',
        'godina',
        'zona_rasvijetljenosti_id',
        'zona_rasvijetljenosti_opis',
        'opis_podrucja',
        'svjetlostaj_od',
        'svjetlostaj_do',
        'zastita',
        'povrsina',
      ],
    },
  },
  fields: [
    coreFields.id,
    {
      title: 'Maticni broj JLS',
      source: 'mb_jls',
      ttoken: 'rasvjeta:plan.mb_jls',
      type: 'numeric',
      readonly: true,
      filter: true,
    },
    {
      title: 'Ime JLS',
      source: 'ime_jls',
      ttoken: 'rasvjeta:plan.ime_jls',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'Zona rasvijetljenosti',
      source: 'zona_rasvijetljenosti_id',
      ttoken: 'rasvjeta:plan.zona_rasvijetljenosti',
      type: 'picker',
      readonly: true,
      filter: true,
      items: {
        labels: ['E0', 'E1', 'E2', 'E3', 'E4'],
        values: [1, 2, 3, 4, 5],
      },
    },
    {
      title: 'Opis zone rasvijetljenosti',
      source: 'zona_rasvijetljenosti_opis',
      ttoken: 'rasvjeta:plan.zona_rasvijetljenosti_opis',
      type: 'picker',
      readonly: true,
      translate: true,
      items: {
        labels: ['rasvjeta:plan.zona.1', 'rasvjeta:plan.zona.2', 'rasvjeta:plan.zona.3', 'rasvjeta:plan.zona.4', 'rasvjeta:plan.zona.5'],
        values: [1, 2, 3, 4, 5],
      },
    },
    {
      title: 'Opis podrucja',
      source: 'opis_podrucja',
      ttoken: 'rasvjeta:plan.opis_podrucja',
      type: 'multiline',
      readonly: true,
      rows: 2,
      maxRows: 2,
      filter: true,
      width: 'full',
    },
    {
      title: 'Svjetlostaj od',
      source: 'svjetlostaj_od',
      ttoken: 'rasvjeta:plan.svjetlostaj.od',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'Svjetlostaj do',
      source: 'svjetlostaj_do',
      ttoken: 'rasvjeta:plan.svjetlostaj.do',
      type: 'text',
      readonly: true,
      filter: true,
    },
    {
      title: 'Zastita',
      source: 'zastita',
      ttoken: 'rasvjeta:plan.zastita',
      type: 'boolean',
      readonly: true,
      filter: true,
      items: {
        labels: ['common.ne', 'common.da'],
        values: [false, true],
        default: true,
      },
      translate: true,
    },
    {
      title: 'Godina',
      source: 'godina',
      ttoken: 'rasvjeta:plan.godina',
      type: 'numeric',
    },
    {
      title: 'Povrsina',
      source: 'povrsina',
      ttoken: 'rasvjeta:plan.povrsina',
      type: 'numeric',
    },
    {
      title: 'geometry',
      source: 'geom',
      ttoken: 'comments.geometry',
      type: 'wkt',
      sourceProjection: 'EPSG:3765',
      targetProjection: 'EPSG:3857',
      readonly: true,
    },
    commonFields.wkt,
    coreFields.gid,
    coreFields.created_by,
    coreFields.created_on,
    coreFields.modified_by,
    coreFields.modified_on,
  ],
};

export default PlanRasvjete;
