/* eslint-disable */
import React, { FC } from 'react';

// Custom Components
import DataCentricPage from '@/ui/DataCentric/DataCentricPage';
import modelComments from '@/models/comments';
import modelCommentPhotos from '@/models/comments_photos';
import Permissions from '@/lib/permissions';
import AppPage from '@/ui/AppPage/AppPage';
import CommentDialog from './CommentDialog';

type CommentsDataCentricPageProps = {};

const CommentsDataCentricPage: FC<CommentsDataCentricPageProps> = (props) => (
  <AppPage
    titlett="page.comment_management"
    needPermission={[Permissions.Private]}
  >
    <DataCentricPage
      primaryModel={modelComments}
      primaryTitleToken="titles.comments"
      primaryView="tenant"
      primaryDialogComponent={CommentDialog}
      primaryAllowEdit
      secondaryModel={modelComments}
      secondaryTitleToken="titles.comments_object"
      secondaryView="tenant_secondary"
      secondaryDialogComponent={CommentDialog}
      secondaryAllowEdit
      baseRecordPath={modelComments.apiPath}
      secondaryBaseRecordPath={modelComments.apiPath+"/entity"}
      primaryQueryAttribute='entity_gid'
      childRecordRelativePath=""
      geomRelativePath=""
      allowShowImages={false}
      modelPhotos={modelCommentPhotos}
      mapId={11}
      mapModel={modelComments}
    />
  </AppPage>
);

export default CommentsDataCentricPage;
