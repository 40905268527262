/* eslint-disable */
import { IFieldGID, IModel } from '../@types/models/model';
import commonFields from './helpers/commonFields';
import coreFields from './helpers/coreFields';

const Comments: IModel = {
  title: 'Comments',
  apiPath: 'comments',
  forms: {
    default: {
      fields: ['note', 'slike', 'is_private'],
    },
    insert: {
      fields: ['note', 'slike', 'is_private'],
    },
    update: {
      fields: ['note', 'slike', 'is_private'],
    },
  },
  listViews: {
    default: {
      fields: ['id', 'created_by', 'note', 'show_on_map', 'slike'],
      hidden: ['id'],
    },
    tenant: {
      fields: ['id', 'note', 'created_by', 'created_on', 'modified_by', 'modified_on','slike','comment_type','is_private'],
      hidden: ['modified_by', 'modified_on'],
    },
    tenant_secondary: {
      fields: ['id', 'note', 'created_by', 'created_on', 'modified_by', 'modified_on','slike','is_private'],
      hidden: ['modified_by', 'modified_on'],
    },
    admin: {
      fields: [
        'id',
        'note',
        'tenant_id',
        'created_by',
        'author_type_id',
        'created_on',
        'modified_by',
        'modified_on',
        'slike',
        'comment_type',
        'is_private',
      ],
    },
    admin_secondary: {
      fields: [
        'id',
        'note',
        'tenant_id',
        'created_by',
        'author_type_id',
        'created_on',
        'modified_by',
        'modified_on',
        'slike',
        'is_private',
      ],
    },
    map: {
      fields: ['id', 'created_by', 'note'],
      hidden: ['id'],
    },
    info_dialog: {
      fields: ['id', 'note', 'created_by', 'created_on', 'slike'],
      hidden: ['id'],
    },
  },
  fields: [
    coreFields.id,

    coreFields.gid,
    {
      title: 'Entity GID',
      source: 'entity_gid',
      ttoken: 'comments.entity_gid',
      type: 'text',
      filter: true,
      readonly: true,
    } as IFieldGID,
    {
      title: 'Komentar',
      source: 'note',
      ttoken: 'comments.note',
      type: 'multiline',
      rows: 3,
      maxRows: 10,
      validation: {
        maxLength: 300,
        required: true,
      },
      filter: true,
    },
    {
      title: 'Created by',
      source: 'created_by',
      ttoken: 'comments.author',
      type: 'text',
      sort: true,
      filter: true,
    },
    {
      title: 'x',
      source: 'x',
      ttoken: 'data.x',
      type: 'numeric',
      readonly: true,
    },
    {
      title: 'y',
      source: 'y',
      ttoken: 'data.y',
      type: 'numeric',
      readonly: true,
    },
    {
      title: 'Datum i vrijeme kreacije',
      source: 'created_on',
      ttoken: 'common.created_on',
      type: 'datetime',
      filter: true,
      sort: true,
      readonly: true,
    },
    {
      title: 'Datum i vrijeme izmjene',
      source: 'modified_on',
      ttoken: 'common.modified_on',
      type: 'datetime',
      filter: true,
      sort: true,
      readonly: true,
    },
    {
      title: 'show_on_map',
      source: 'show_on_map',
      type: 'button',
      ttoken: 'buttons.show_on_map',
      icon: 'fmd_good',
      action: 'show_on_map',
      padding: 'none',
      sort: false,
    },
    {
      title: 'extent',
      source: 'extent',
      ttoken: 'comments.extent',
      type: 'array',
      readonly: true,
    },
    {
      title: 'geometry',
      source: 'geom',
      ttoken: 'comments.geometry',
      type: 'wkt',
      readonly: true,
    },
    {
      title: 'slike',
      source: 'slike',
      ttoken: 'comments.photos',
      type: 'images',
      maxFiles: 3,
      subModel: {
        apiPath: 'comments/photos',
        format: '',
      },
    },
    {
      title: 'tenant_id',
      source: 'tenant_id',
      ttoken: 'admin:common.tenant_id',
      type: 'picker',
      filter: true,
      subModel: {
        apiPath: 'system/tenants?view=picker',
        format: '{label}',
        idattr: 'id',
      },
      validation: {
        required: true,
      },
    },
    {
      title: 'Tip korisnika autora',
      source: 'author_type_id',
      ttoken: 'admin:comments.users.type',
      filter: true,
      translate: true,
      type: 'picker',
      items: {
        labels: [
          'admin:user_types.regular',
          //'admin:user_types.public', // we don't want to add new public users. the only public user is set on tenant creation.
          'admin:user_types.service',
          'admin:user_types.internal',
        ],
        values: [
          1,
          //2,
          3, 4,
        ],
        default: 1,
      },
      validation: {
        required: true,
      },
    },
    {
      title: 'Tip komentara',
      source: 'comment_type',
      ttoken: 'admin:comments.type',
      type: 'picker',
      items: {
        labels: ['admin:comments.types.drawn', 'admin:comments.types.object'],
        values: [0, 1],
        icons: ['draw', 'location_on'],
      },
      filter: true,
      readonly: true,
    },
    {
      title: 'Private',
      source: 'is_private',
      ttoken: 'comments.is_private',
      type: 'boolean',
      items: {
        labels: ['common.no', 'common.yes'],
        values: [false, true],
        default: false,
      },
      tooltip: 'comments.tooltips.is_private',
    },
    coreFields.created_on,
    coreFields.modified_by,
    coreFields.modified_on,
  ],
};

export default Comments;
