import React from 'react';

// Custom Components
import DataCentricPage from '@/ui/DataCentric/DataCentricPage';
import modelComments from '@/models/comments';
import Permissions from '@/lib/permissions';
import AppPage from '@/ui/AppPage/AppPage';
import CommentDialog from '@/views/Comments/CommentDialog';

const CommentsAdministrationDataCentricPage = () => (
  <AppPage titlett="admin:titles.comments" needPermission={[Permissions.Private]}>
    <DataCentricPage
      tableIdentifier="comments_admin"
      primaryModel={modelComments}
      primaryTitleToken="admin:titles.comments"
      primaryView="admin"
      primaryDialogComponent={CommentDialog}
      primaryAllowEdit
      secondaryModel={modelComments}
      secondaryTitleToken="titles.comments_object"
      secondaryView="admin_secondary"
      secondaryDialogComponent={CommentDialog}
      secondaryAllowEdit
      baseRecordPath={`system/${modelComments.apiPath}`}
      secondaryBaseRecordPath={`system/${modelComments.apiPath}/entity`}
      primaryQueryAttribute="entity_gid"
      childRecordRelativePath=""
      geomRelativePath=""
      allowShowImages={false}
      mapId={11}
      mapModel={modelComments}
    />
  </AppPage>
);

export default CommentsAdministrationDataCentricPage;
