/* eslint-disable */
import { FunctionComponent, useContext, useEffect } from "react";

import { MouseWheelZoom } from "ol/interaction";

//Custom components
import MapContext, { MapContextType }  from "@/context/MapContext/MapContext";
import { registerOlInteraction } from "@/lib/olHelpers";

//Types
import { IMouseWheelInteraction } from "@/@types/components/Map/Interactions/OpenLayers";

const MouseWheelZoomInteraction: FunctionComponent<IMouseWheelInteraction> = (props) => {
  const context = useContext(MapContext) as MapContextType;

  const options = {
    condition: undefined,
    maxDelta: undefined,
    duration: undefined,
    timeout: undefined,
    useAnchor: undefined
  };

  const events = {
    change: undefined,
    "change:active": undefined,
    error: undefined,
    propertychange: undefined
  };

  useEffect(
    () =>
      registerOlInteraction(context, MouseWheelZoom, props, options, events),
    []
  );

  return null;
};

export default MouseWheelZoomInteraction;

