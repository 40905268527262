/* eslint-disable */
import React from 'react';

// MUI
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { Badge } from '@mui/material';

// MUI Icons
import LayersIcon from '@mui/icons-material/Layers';

const MapCardHeaderButtonLayers = (props: IconButtonProps & {layerCount?: number} ) => (
  <IconButton
    color="inherit"
    aria-label="layers"
    aria-controls="layers"
    {...props}
  >
    <Badge badgeContent={props.layerCount ?? 0} color='secondary'><LayersIcon sx={{color: "white"}}/></Badge>
  </IconButton>
);

export default MapCardHeaderButtonLayers;
