/* eslint-disable */
import React from "react";

//MUI
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useTranslation } from 'react-i18next';

const HomePageFooter = () => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ m: 2, }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Box m={1}>
        <Typography variant="caption">PAW v2.0</Typography>
      </Box>
      <Box m={1}>
        <Typography variant="caption">© {new Date().getFullYear()} Prehnit, {t('copyright')}</Typography>
      </Box>
    </Stack>
  )
}

export default HomePageFooter;
